import React, { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useForm, Controller } from 'react-hook-form';
import {
  Grid,
  Box,
  Button as MaterialButton,
  Radio,
  FormControlLabel,
  FormControl,
  RadioGroup,
  FormLabel,
} from '@mui/material';
import {
  Button,
  InlineAlert,
  Preloader,
  Typography,
} from '@northwell-health/nw-component-lib';
import { PHQ2 } from '../../forms/phq2';
import { formatPHQ2Results } from '../../utils/helpers';
import * as touchworksService from '../../services/phq2/touchworks';
import InformationPage from './information';
import ResultsPage from './results';

const progressLeft = document.getElementsByClassName('progress-indicator-left');
const progressRight = document.getElementsByClassName(
  'progress-indicator-right',
);
const pageTitle = 'Patient health questionnaire form';

export default function MentalHealthScreener(props) {
  // Breakpoints
  const isMobile = useMediaQuery({ maxWidth: 767 });
  // Temp solution to ElectronicSignature.css file overwriting button styles across the app.
  const [hoverSubmit, setHoverSubmit] = useState(false);
  const [hoverReset, setHoverReset] = useState(false);

  const { patientIdentifier } = props;
  // Set loading state.
  const [loading, setLoading] = useState(false);
  // Set error alert state.
  const [errorAlert, setErrorAlert] = useState(false);
  // Set error alert message string.
  const [errorAlertMessage, setErrorAlertMessage] = useState('');
  // Set data for PHQ2
  const [token, setToken] = useState('');
  const [touchworksID, setTouchworksID] = useState('');
  const [providerID, setProviderID] = useState('');
  const [encounterID, setEncounterID] = useState('');
  const [informationPageShow, showInfoPage] = useState(false);
  const [resultsPageShow, showResultsPage] = useState(false);
  const [phqScore, setPhqScore] = useState(0);

  // Handle starting PHQ form.
  const startPHQ = () => {
    showInfoPage(false);
    showResultsPage(false);
  };

  useEffect(() => {
    const startPhqProcess = async () => {
      try {
        setLoading(true);
        // Set page titles
        document.querySelector('title').textContent = pageTitle;
        document.getElementById('pageTextHeader').innerHTML = pageTitle;
        progressLeft[0].style.width = '25%';
        progressRight[0].style.width = '75%';

        // Get Touchworks SSO Token
        const token = await touchworksService.getTouchworksToken();
        setToken(token);
        // Authenticate user and get provider id.
        const providerId = await touchworksService.getProviderID(token);
        setProviderID(providerId);
        // Get Internal Touchworks patient id for creating encounter.
        const internalTouchWorksPatientId =
          await touchworksService.getTouchworksPatientID(
            token,
            patientIdentifier,
          );
        setTouchworksID(internalTouchWorksPatientId);
        // Get encounter for patient.
        const encounterId = await touchworksService.createEncounter(
          token,
          internalTouchWorksPatientId,
        );
        setEncounterID(encounterId);
        setLoading(false);
        showInfoPage(true);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };

    startPhqProcess();
  }, []);

  const {
    reset,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {
      questionOne: '',
      questionTwo: '',
    },
  });

  // Handle submitting the form.
  const onSubmit = async (data) => {
    try {
      setLoading(true);
      // Check for required params.
      if (!token || !providerID || !encounterID || !touchworksID) {
        throw 'Token, Provider Id, Encounter ID, or Internal Patient ID missing';
      }
      // Calculate score.
      const score = parseInt(data.questionOne) + parseInt(data.questionTwo);
      setPhqScore(score);
      // Format PHQ2 results
      const formattedResults = formatPHQ2Results(
        patientIdentifier,
        providerID,
        encounterID,
        data.questionOne,
        data.questionTwo,
        score,
      );
      // Post results to Touchworks.
      const results = await touchworksService.saveMultipleResults(
        patientIdentifier,
        token,
        formattedResults,
      );
      // Check for failure.
      if (!results || results !== 'Success') {
        throw 'Something went wrong!';
      }
      // Retire the token.
      await touchworksService.retireTouchworksToken(token);
      // Show results page.
      showInfoPage(false);
      setLoading(false);
      showResultsPage(true);
    } catch (e) {
      setLoading(false);
      // Set Error message
      setErrorAlertMessage('We ran into an issue. Please try again');
      //Keep error alert true for 5 seconds.
      setErrorAlert(true);
      setTimeout(() => {
        setErrorAlert(false);
      }, 5000);
    }
  };

  // Show loading screen.
  if (loading) {
    return <Preloader fullScreen text="Loading ..." />;
  }

  // Show information page.
  if (informationPageShow) {
    return (
      <InformationPage
        onFormStateChange={startPHQ}
        patientIdentifier={patientIdentifier}
        token={token}
        providerID={providerID}
        encounterID={encounterID}
        touchworksID={touchworksID}
      />
    );
  }

  // Show results page.
  if (resultsPageShow) {
    return <ResultsPage score={phqScore} />;
  }

  return (
    <form name="PHQFORM">
      <Box className="pageContainer">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              type="h1"
              gutterBottom={true}
              style={{ marginTop: `${isMobile ? '40px' : '80px'}` }}
            >
              Over the last 2 weeks, how often have you been bothered by the
              following problems?
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              display: 'block',
            }}
          >
            <Typography
              type="body"
              size="small"
              weight="bold"
              style={{ color: 'red' }}
            >
              {'* '}
            </Typography>
            <Typography type="body" size="small" weight="bold">
              Required
            </Typography>
          </Grid>
          {/** In line error alert */}
          {errorAlert && (
            <Grid item xs={12} style={{ marginBottom: 24 }}>
              <InlineAlert severity="error" text={errorAlertMessage} />
            </Grid>
          )}
          {PHQ2.questions.map((question) => {
            return (
              <Grid item xs={12} style={{ marginBottom: 18 }} key={question.id}>
                <FormControl component="fieldset" key={question.id}>
                  <FormLabel
                    style={{ fontSize: 18, marginBottom: 12 }}
                    component="legend"
                  >
                    {question.value}
                  </FormLabel>
                  <Controller
                    rules={{ required: true }}
                    control={control}
                    name={question.id}
                    render={({ field }) => {
                      return (
                        <RadioGroup row={isMobile ? false : true} {...field}>
                          {PHQ2.answers.map((answer, i) => {
                            return (
                              <FormControlLabel
                                key={i}
                                value={answer.numValue}
                                control={<Radio />}
                                label={answer.textValue}
                              />
                            );
                          })}
                        </RadioGroup>
                      );
                    }}
                  />
                </FormControl>
              </Grid>
            );
          })}
          <Grid container spacing={4} style={{ margin: 'inherit' }}>
            <Grid item>
              <MaterialButton
                onMouseEnter={() => setHoverSubmit(true)}
                onMouseLeave={() => setHoverSubmit(false)}
                style={{
                  minWidth: '64px',
                  fontFamily:
                    'TheSans, BlinkMacSystemFont, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                  textTransform: 'none',
                  fontSize: '1rem',
                  fontWeight: 'normal',
                  lineHeight: '24px',
                  borderRadius: '5px',
                  padding: '0px 32px',
                  height: '48px',
                  backgroundColor: hoverSubmit ? '#003CA5' : 'rgb(0, 123, 194)',
                  color: 'rgb(255, 255, 255)',
                  boxShadow: 'none',
                }}
                variant="contained"
                size="large"
                onClick={handleSubmit(onSubmit)}
              >
                Submit
              </MaterialButton>
            </Grid>
            <Grid item>
              <MaterialButton
                onMouseEnter={() => setHoverReset(true)}
                onMouseLeave={() => setHoverReset(false)}
                style={{
                  minWidth: '64px',
                  fontFamily:
                    'TheSans, BlinkMacSystemFont, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                  textTransform: 'none',
                  fontSize: '1rem',
                  fontWeight: 'normal',
                  lineHeight: '24px',
                  borderRadius: '5px',
                  padding: '0px 32px',
                  height: '48px',
                  backgroundColor: 'rgb(255, 255, 255)',
                  color: hoverReset ? '#003CA5' : 'rgb(0, 123, 194)',
                  border: hoverReset
                    ? '1px solid #003CA5'
                    : '1px solid rgb(0, 123, 194)',
                  boxShadow: 'none',
                }}
                variant="outlined"
                size="large"
                onClick={() => reset()}
              >
                Reset
              </MaterialButton>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
}
