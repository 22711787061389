
import envConfig from "../configEnv/config"

export async function verifyEmailAccess(externalid) {
  const dpxURL = 'https://' + envConfig?.REACT_APP_DPXURL;
  //Local Testing
  //const dpxURL = 'https://' + envConfig?.REACT_APP_TEMPDPXURL;
  const verifyUrl = `${dpxURL}/iforms/verifyToken`;
  const iformHeaders = new Headers({
    "Content-Type": "application/json"
  });

  const payload = JSON.stringify({ externalid });

  try {
    const res = await fetch(verifyUrl, {
      method: "POST",
      headers: iformHeaders,
      body: payload
    });

    if (!res.ok) {
      return false;
    }

    const data = await res.json();

    if (data.postVerifyLiteTokenResponse) {
      return true;
    } else {
      return false;
    }
  } catch (err) {
    console.error('Account Invite Error', err);
    return false;
  }
}
