import React from 'react';
import { useMediaQuery } from 'react-responsive';

export default function northwellLog() {
  // Breakpoints
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return (
    <div
      className="northwell-head-container"
      style={
        isMobile
          ? {
              maxWidth: 'fit-content',
              marginLeft: 'auto',
              marginRight: 'auto',
              height: '70px',
            }
          : {}
      }
    >
      <div className="header-container">
        <img className="centerImage" src="/images/NorthwellHealthLogo.svg" />
      </div>
    </div>
  );
}
