//surveyQuestions.questions

const dynamicIformMd = {
    "QuestionSet":[{
        questionSetTitle:"Over the last 2 weeks, how often have you been bothered by the following problems?",
        questionSetTitle_sp:"Durante las últimas 2 semanas, ¿con qué frecuencia le han molestado los siguientes problemas?"
    }],
    "Questions": [{
            questionLabel: "Little interest or pleasure in doing things:",
            questionLabel_sp: "Poco interés o placer en hacer las cosas:",
            formId: "survey01",
            questionId: "survey01_01",
            answerId: "questionGrp01",
            type: "survey",
            answered: ""
        },
        {
            questionLabel: "Feeling down, depressed or hopeless:",
            questionLabel_sp: "Sentirse deprimido, deprimido o sin esperanza:",
            formId: "survey01",
            questionId: "survey01_02",
            answerId: "questionGrp01",
            type: "survey",
            answered: ""
        }
    ],
    "Answers": {
        "questionGrp01": {
            "questions": [{
                    answer: "Not at All",
                    answer_sp: "De nada",
                    numValue: 0
                },
                {
                    answer: "Several days",
                    answer_sp: "Varios dias",
                    numValue: 1
                },
                {
                    answer: "More than half the days",
                    answer_sp: "Más de la mitad de los días",
                    numValue: 2
                },
                {
                    answer: "Nearly every day",
                    answer_sp: "Casi todos los dias",
                    numValue: 3
                }
            ],
        }
    }
};

export default dynamicIformMd;