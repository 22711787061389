import envConfig from './configEnv/config';

const touchworksPHQ2Order = envConfig?.REACT_APP_PHQ2_ORDER || '9274';
const touchworksPHQ2Score = envConfig?.REACT_APP_PHQ2_SCORE || '7841';
const touchworksPHQ2Screening = envConfig?.REACT_APP_PHQ2_SCREENING || '7840';
/**
 * Helper method to format an inputted phone number.
 * @param {String} phoneNumber - String number to format
 * @returns {String}
 */
export const formatPhoneNumber = (phoneNumber) => {
  // Format the phone number
  const formattedPhone = `+1 (${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
    3,
    6,
  )}${phoneNumber.slice(6)}`;
  return formattedPhone;
};

/**
 * Helper method to format an inputted date of birth to YYYY-MM-DD format.
 * @param {String} dateToFormat - String date to format
 * @returns {String}
 */
export const formatDateOfBirth = (dateToFormat) => {
  // Split the input date string by '/'
  const [month, day, year] = dateToFormat.split('/');

  // Return the date in 'YYYY-MM-DD' format
  return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
};

/**
 * Helper method to format today's date and time to DD MMM YYYY H:MM A.
 * @returns {String}
 */
export const getFormattedDateTime = () => {
  const date = new Date();
  // Format date for touchworks format of DD MMM YYYY
  const formattedDate = new Intl.DateTimeFormat('en-GB', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    timeZone: 'America/New_York',
  }).format(date);

  // Format time for 12-hour clock with AM/PM in the same time zone
  const formattedTime = new Intl.DateTimeFormat('en-US', {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
    timeZone: 'America/New_York',
  }).format(date);

  return `${formattedDate} ${formattedTime}`;
};

/**
 * Helper method to format the PHQ-2 results to match what Touchworks is expecting.
 * @returns {String}
 */
export const formatPHQ2Results = (
  patientId,
  providerId,
  encounterId,
  q1Result,
  q2Result,
  score,
) => {
  const dateTime = getFormattedDateTime();
  const resultStatus =
    typeof score === 'number' ? (score >= 3 ? 'Positive' : 'Negative') : score;
  // Add the appropriate date to the format.
  const formattedString = `<formdata>\r\n <orderdetails>\r\n <PatientID value=\"${patientId}\" />\r\n <PatientIDType value=\"MRN\" />\r\n<OrganizationID value=\"9\" />\r\n <OrderDictID value=\"${touchworksPHQ2Order}\" />\r\n <OrderingProvderID value=\"${providerId}\" />\r\n<EncounterID value=\"${encounterId}\" />\r\n<OrderComments value=\"&#xD;&#xA; Over the last 2 weeks, how often have you been bothered by the following problems?&#xD;&#xA;Little interest or pleasure in doing things: ${q1Result}&#xD;&#xA;Feeling down, depressed or hopeless: ${q2Result}&#xD;&#xA;Documented via Patient Questionnaire\" />\r\n<AcknowledgementReqFLAG value=\"N\" />\r\n <IncludeDefaultChargeFLAG value=\"Y\" />\r\n </orderdetails>\r\n <results>\r\n <result>\r\n <ResultDictID value=\"${touchworksPHQ2Score}\" />\r\n<ResultValue value=\"${score}\" />\r\n <ResultAbnormalFLAG value=\"N\" />\r\n <ResultDate value=\"${dateTime}\" />\r\n <ResultUnits value=\"\" />\r\n<HasExternalStorageData value=\"N\" />\r\n <ResultNoteText />\r\n </result>\r\n <result>\r\n <ResultDictID value=\"${touchworksPHQ2Screening}\" />\r\n <ResultValue value=\"${resultStatus}\" />\r\n<ResultAbnormalFLAG value=\"N\" />\r\n <ResultDate value=\"${dateTime}\" />\r\n <ResultUnits value=\"\" />\r\n <HasExternalStorageData value=\"N\" />\r\n<ResultNoteText value=\"&#xD;&#xA; Over the last 2 weeks, how often have you been bothered by the following problems?&#xD;&#xA;Little interest or pleasure in doing things: ${q1Result}&#xD;&#xA;Feeling down, depressed or hopeless: ${q2Result}&#xD;&#xA;Documented via Patient Questionnaire\" />\r\n</result>\r\n </results>\r\n</formdata>`;
  // Return the formatted string
  return formattedString;
};
