import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { Grid } from '@mui/material';
import {
  Divider,
  LinkPlusCard,
  Typography,
} from '@northwell-health/nw-component-lib';

const highScore = () => {
  // Breakpoints
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return (
    <Grid>
      <Grid item xs={12} style={{ marginBottom: 12 }}>
        <Typography
          variant="body"
          size={isMobile ? 'small' : 'large'}
          weight="light"
        >
          Your screening may indicate depression. Please make an appointment
          with your primary care doctor, or visit the links below.
        </Typography>
        <Divider variant="fullWidth" />
      </Grid>
      <Grid item xs={12} style={{ marginBottom: 24 }}>
        <Grid container spacing={2} direction={isMobile ? 'column' : 'row'}>
          <Grid item xs={4}>
            <LinkPlusCard
              description="24/7 support available for those in need."
              headline="Visit Suicide Prevention Lifeline"
              linkUrl="https://suicidepreventionlifeline.org"
              openInNewTab={true}
            />
          </Grid>
          <Grid item xs={4}>
            <LinkPlusCard
              description="1-800-273-8255"
              headline="Call Visit Suicide Prevention Lifeline"
              linkUrl="tel:+18002738255"
              openInNewTab={false}
            />
          </Grid>
          <Grid item xs={4}>
            <LinkPlusCard
              description="(888) 321-DOCS"
              headline="Call for a referral"
              linkUrl="tel:+18883213627"
              openInNewTab={false}
            />
          </Grid>
          <Grid item xs={4}>
            <LinkPlusCard
              description="Find a care provider using our search tool."
              headline="Find care"
              linkUrl="https://www.northwell.edu/find-care"
              openInNewTab={true}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography
          type={isMobile ? 'h3' : 'h4'}
          weight="Plain"
          style={{ marginBottom: 12 }}
        >
          You can also connect 24/7 to a crisis counselor by texting the Crisis
          Text Line.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography type={isMobile ? 'h3' : 'h4'} weight="Plain">
          <ul>
            <li>
              Text <strong>HOME</strong> to 741741
            </li>
          </ul>
        </Typography>
      </Grid>
    </Grid>
  );
};
export default highScore;
