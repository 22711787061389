import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { Box, Grid } from '@mui/material';
import { Typography } from '@northwell-health/nw-component-lib';

const OptOutPage = () => {
  // Breakpoints
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return (
    <Box className="pageContainer">
      <Grid
        container
        direction="column"
        justifyContent="space-between"
        alignItems="left"
      >
        <Grid item xs={12}>
          <Typography
            type="h1"
            style={{ marginTop: `${isMobile ? '40px' : '80px'}` }}
          >
            You have chosen to opt out of taking the Patient Health
            Questionnaire at this time.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};
export default OptOutPage;
