import React, { useState } from "react";
import "./uploadcontrol.css";
import envConfig from "../../utils/configEnv/config";
import { useNavigate, useParams } from "react-router-dom";

export default function uploadFileCtrl(props) {
  const navigate = useNavigate();

  const { uploadStateChange } = props;
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(null);
  const [uploadComplete, setUploadComplete] = useState(null);
  const [ fileFormatMsg, setfileFormatMsg ] = useState(false);
  const [ uploadState, setUploadState] = useState(false);
  const [isFileTypeAllowed, setFileTypeAllowed ] = useState(true);
  
  const UPLOAD_ENDPOINT = envConfig?.REACT_APP_UPLOAD_ENDPOINT;

  const handleFileSubmit = async e => {   
    e.preventDefault();
    for (var i = 0; i < file.length; i++) {
      setUploading(true);
      let res = await uploadFile(file[i]);
      if (res.status === 200) {
        setUploadComplete(true);
        setUploading(false);      
        setUploadState(false)
        uploadStateChange(true);
      }else{
      //alert('Upload Failed');
      navigate('/Error/'+'upload failed');
    }
  }
};
  //Upload A Single file with Custom Header for Logic App.
  //Logic App Didn't handle FormData Well
  const uploadFile = async file => {
    var imageURI = {	
       owner: props.patientName, 
       type: file.type,
       name: file.name,
       };
       try{
    return await fetch(
      UPLOAD_ENDPOINT,
      {
        method: 'post',
        body: file,
        headers: {
          'Content-Type': 'multipart/form-data; ',
          'formid': props.formid,
          'Image-Name-Str':  imageURI.name,
          'Image-Type-Str': imageURI.type,
          'Image-Owner-Str': imageURI.owner
        },
      }
    );
       } catch(e){
        navigate('/Error/'+'upload failed');
      }
  };

  //Set acceptable types - note: always ensure backend does as well
  function isCorrectType(fileType) {   
    switch (fileType.toLowerCase()) {
      case 'image/png':
      case 'image/jpeg':
      case 'image/jpg':
      case 'application/pdf':        
        return true;
    }
    return false;
  }

  const handleOnFileChange = e => {
    // Check to see what Type of File
    setUploadState(false);
    setUploadComplete(false);
    uploadStateChange(false);
    setfileFormatMsg(false);
    for (var i = 0; i < e.target.files.length; i++) {
      if(isCorrectType(e.target.files[i].type)){
        setUploadState(true);
        setfileFormatMsg(true);
      }else{
        setFileTypeAllowed(false);
        setUploadState(false);
      }
    }
    setFile(e.target.files)
  };

    return (
        <div>
          <input type="file" 
                form="uploadForm"
                id="files" 
                name="files" 
                multiple="multiple" 
                onChange={handleOnFileChange}
                accept="image/png, image/jpeg, application/pdf"
                  />
                  { uploadState ?<button className="blockDis" onClick={handleFileSubmit} type="button">Upload File</button> : null}
                  { uploading && <p>Uploading file(s)</p> }
                  { uploadComplete && <p className="uploadedFiles">Your file(s) have been uploaded</p> }
                  {!fileFormatMsg ?<p className="uploadError">Please select a PDF, JPG or PNG of documentation required. Multiple file uploads allowed.</p>: null}                  
                  {!isFileTypeAllowed ?<p className="uploadError">Only PDF, JPG or PNG files are allowed.</p>: null}                  
        </div>
    );
  }
  