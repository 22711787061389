import React, { useState, useRef } from "react";
import { useForm } from "react-hook-form";
import SignaturePad from 'react-signature-canvas';
import "../formcomponents/ElectronicSignature.css";
import { Grid, Box, Paper } from '@mui/material';
import { Typography } from '@northwell-health/nw-component-lib'
import styled from '@emotion/styled';

const Item = styled(Paper)(({ theme }) => ({
 // ...theme.typography.body2,
  textAlign: 'left',
  minHeight: '65px',
  lineHeight: '60px',
}));

const styles = {
  signatureSectionCont: {
    padding: '10px'
  },
  errorMsgCont:{
    display: 'block',
    clear: 'both',
    width: '100%',
    paddingTop: '40px'
  }
};

const minorPageThree = (props) => {
  const { SubmissionState, onFormStateChange } = props;
  const { register, handleSubmit, setValue, formState: {}} = useForm();
  const [ isSigned, setIsSigned ] = useState(false);
  const [imageURL, setImageURL] = useState(null); 
  const signData = useRef({});
  
  const setCardColor =function(color){
    let SigCardSt = document.getElementsByClassName("Card");
    let SigPadBk = document.getElementsByClassName("SigPad");
    SigPadBk[0].style.backgroundColor = color;
    SigCardSt[0].style.backgroundColor = color;
  }
  
  const clear = () => {
    setImageURL("reset");
    setIsSigned(false),    
    signData.current.clear(),
    setCardColor("#FAFAFA")
    };

  const save = () => setImageURL(   
    signData.current.getTrimmedCanvas().toDataURL("image/png"),
    setValue("imageURLStr", "True"),
    setCardColor("#E8F0FE"),
    setIsSigned(true)
    );
    
  const saveifNotEmpty = () => {
    if(signData.current.isEmpty()){
      alert('You must sign document'); 
    }else{
      save();
    }
  };
  
  const handleRegistration = (data) => {
    if(imageURL)  {
      setValue("imageURLStr", "True");
      setIsSigned(true);
    }
    
    data.imageUrl = imageURL;
    data.uploadFiles = "See Folder Images";
    SubmissionState.sec = data;
    SubmissionState.sectionThreeForm = data;
    SubmissionState.sectionThreSubmitted = true ;    
    SubmissionState.goBackPageOne = false;
    SubmissionState.goBackPageTwo = false;
    SubmissionState.goBackLogin = false;    
    SubmissionState.submitCompletedForm = true;
    onFormStateChange();
  };

  const previousPageClick = () => {
    SubmissionState.showPageThree = false;
    SubmissionState.showPageTwo = true;
    SubmissionState.goBackPageTwo = true;
    SubmissionState.goPageThree = false;
    onFormStateChange();
  };

  const scrolltoTop = function() {
    window.scrollTo(0, 0);
  };

  scrolltoTop();
  
  return (  
    <Box className="pageContainer">
      <Grid container
        spacing={1}
        justifyContent="space-between"
        alignItems="left">
        <Grid item xs={12} sm={12} md={12} >
              <p className="breadcrumb_nav" onClick={previousPageClick}>&#60; Your Information</p>
        </Grid>
        <Grid item xs={12}>
        <Typography variant="h1" style={{ marginBottom: 24 }}>By signing below, I acknowledge and agree that:</Typography></Grid>         
        <Grid item xs={12} sm={12} md={12}>
            <Item elevation={0}>          
              <ul>
                <li>I am the parent or legal guardian of the patient named above.</li>
                <li>There are no court orders or restraining orders in effect limiting my access to this child's medical records and/or information.</li>
                <li>I must comply with the Terms and Conditions on Northwell's website regarding access to my child's information on Northwell patient portals.</li>
                <li>Access to Northwell patient portals is provided by Northwell as a convenience to its patients and families and Northwell may deactivate access to Northwell patient portals at any time for any reason.</li>
                <li>I understand that Proxy access will include select, limited medical information from my child's medical record. I may request a copy of my child's full medical record through other means.</li>
                <li>I understand that Proxy access to my child's account will automatically terminate when my child reaches 13 years of age.</li>
                <li>I hereby certify that I am the parent or legal guardian of the patient listed on this form and have the right to access their health information. (Attach supporting legal documentation if applicable.)</li>
              </ul>
              </Item>
        </Grid>
<Grid item xs={12}><br/></Grid>          
    <Grid item xs={12}>
      <Typography style={{ marginBottom: 24 }}><strong>
I hereby certify that I am the parent or legal guardian of the patient listed on this form and have the right to access their health information.
</strong>
      </Typography>
    </Grid>
  <form onSubmit={handleSubmit(handleRegistration)}>
      <Grid item xs={12}>
      <Item elevation={0}>
          <div className="Card">
          <h2>Signature Required *</h2>
          <div className="SigContainer">
            <SignaturePad penColor="black" canvasProps={{ className: "SigPad" }}  ref={signData} />
            <div>
              {!isSigned? <p className="signatureError">A Signature Is Required. Please sign and click "SAVE" to submit.</p>: null}
              </div>
            <div>
              <button className="blockDis" onClick={clear} type="button">Reset Signature</button>
              <button className="blockDis" onClick={saveifNotEmpty} type="submit">Save</button>
              </div>
            </div>
          </div>
          <input {...register("imageURLStr", { required: true })} type="hidden" />          
          <div style={styles.errorMsgCont}></div>
          </Item>
          </Grid>
      </form>
    </Grid>
  </Box>
  );
};
export default minorPageThree;
