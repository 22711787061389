import React, { useState,useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import SignaturePad from 'react-signature-canvas';
import "../formcomponents/ElectronicSignature.css";
import { Typography } from '@northwell-health/nw-component-lib'
import { Grid, Box, Paper } from '@mui/material';
import styled from '@emotion/styled';

const Item = styled(Paper)(({ theme }) => ({
 // ...theme.typography.body2,
  textAlign: 'left',
  minHeight: '65px',
  lineHeight: '60px',
}));

const styles = {
  signatureSectionCont: {
    padding: '10px'
  },
  errorMsgCont:{
    display: 'block',
    clear: 'both',
    width: '100%',
    paddingTop: '40px'
  }
};

const adultPageThree = (props) => {
  const { SubmissionState, onFormStateChange } = props;
  const { register,  handleSubmit, setValue, formState: {}} = useForm();
  const [ isSigned, setIsSigned ] = useState(false);
  const [imageURL, setImageURL] = useState(null); 
  const signData = useRef({});
  
  const setCardColor =function(color){
    let SigCardSt = document.getElementsByClassName("Card");
    let SigPadBk = document.getElementsByClassName("SigPad");
    SigPadBk[0].style.backgroundColor = color;
    SigCardSt[0].style.backgroundColor = color;
  }
  
  const clear = () => {
    setImageURL("reset");
    setIsSigned(false),    
    signData.current.clear(),
    setCardColor("#FAFAFA")
    };

  const save = () => setImageURL(   
    signData.current.getTrimmedCanvas().toDataURL("image/png"),
    setValue("imageURLStr", "True"),
    setCardColor("#E8F0FE"),
    setIsSigned(true)
    );
    
  const saveifNotEmpty = () => {
    if(signData.current.isEmpty()){
      alert('You must sign document'); 
    }else{
      save();
    }
  };
  
  const handleRegistration = (data) => {
    if(imageURL)  {
      setValue("imageURLStr", "True");
      setIsSigned(true);
    }
    
    data.imageUrl = imageURL;
    data.uploadFiles = "See Folder Images";
    SubmissionState.sec = data;
    SubmissionState.sectionThreeForm = data;
    SubmissionState.sectionThreSubmitted = true ;    
    SubmissionState.goBackPageOne = false;
    SubmissionState.goBackPageTwo = false;
    SubmissionState.goBackLogin = false;    
    SubmissionState.goPageFour = true;
    SubmissionState.submitCompletedForm = false;
    onFormStateChange();
  };

  const previousPageClick = () => {
    SubmissionState.showPageThree = false;
    SubmissionState.showPageTwo = true;
    SubmissionState.goBackPageTwo = true;
    SubmissionState.goPageThree = false;
    onFormStateChange();
  };


  const scrolltoTop = function() {
    window.scrollTo(0, 0);
  };

  scrolltoTop();
  
  return (  
    <Box className="pageContainer">
      <Grid container
        spacing={1}
        justifyContent="space-between"
        alignItems="left">
        <Grid item xs={12} sm={12} md={12} >
              <p className="breadcrumb_nav" onClick={previousPageClick}>&#60; Your Information</p>            
        </Grid>      
        <Grid item xs={12} sm={6} md={12}>
          <Item elevation={0}>
            <Typography variant="h1" style={{ marginBottom: 24 }}>
            I acknowledge and agree that: 
            </Typography>
            </Item>
            <Item elevation={0}>
            <ul>
              <li>I must comply with the Terms and Conditions on the Northwell Website. </li>
              <li>The Proxy, named below, will have access to my MyNorthwell account, in the manner specified above, which includes access to my medical information that is currently available and that may become available as a result of future medical care. </li>
              <li>The Proxy may have access to information that I wish to keep confidential. </li>
              <li>I can revoke the Proxy’s access at any time by visiting the “Share My Record” tab in my MyNorthwell account menu, selecting “Friend and family access,” and clicking the “Revoke” button next to my Proxy’s name, or by submitting a written revocation request by email to: northwellproxy@northwell.edu </li>
              <li>Unless I revoke my Proxy’s access, my Proxy will continue to have access to my MyNorthwell account until I revoke such access.</li>
              <li>Once Northwell discloses health information to a Proxy, it may be re- disclosed and the disclosed information may no longer be protected by federal and state privacy law. </li>
            </ul>
            </Item>
          </Grid>
          <Grid item xs={12}><br/></Grid>
  <form onSubmit={handleSubmit(handleRegistration)}>
      <Grid item xs={12}>
      <Item elevation={0}>
          <div className="Card">
          <h2>Signature Required *</h2>
          <div className="SigContainer">
            <SignaturePad penColor="black" canvasProps={{ className: "SigPad" }}  ref={signData} />
            <div>
              {!isSigned? <p className="signatureError">A Signature Is Required. Please sign and click "SAVE" to submit.</p>: null}
              </div>
            <div>
              <button className="blockDis" onClick={clear} type="button">Reset Signature</button>
              <button className="blockDis" onClick={saveifNotEmpty} type="submit">Save</button>
              </div>
            </div>
          </div>
          <input {...register("imageURLStr", { required: true })} type="hidden" />          
          <div style={styles.errorMsgCont}></div>
          </Item>
          </Grid>
      </form>
    </Grid>
  </Box>
  );
};
export default adultPageThree;
