import React from 'react';
import { useMediaQuery } from 'react-responsive';

export default function northwellheader(props) {
  // Breakpoints
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const { formHeader } = props;
  return (
    <div
      className="blue-bar headerflex"
      style={
        isMobile
          ? {
              height: '50px',
            }
          : {}
      }
    >
      <div className="internal-container" id="pageTextHeader">
        {formHeader}
      </div>
    </div>
  );
}
