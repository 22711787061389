import React from "react";
import { createRoot } from "react-dom/client";
import AppRoutes from "./routes/reactdomroutes";
import { ThemeProvider,nwTheme } from "@northwell-health/nw-component-lib";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fal } from "@fortawesome/pro-light-svg-icons";
import "./iformStyles.css";
import NorthwellHeader from "./components/northwellheader/northwellheader";
import NorthwellLogo from "./components/northwellheader/northwelllogoBar";
import ProgressBar from "./components/northwellheader/progressindicator";
import { Footer } from "@northwell-health/nw-component-lib";
library.add(fal);

const defaultHeader = "FollowMyHealth minor consent form";

function App() {

  return (
    <div className="App">
      <NorthwellLogo />
      <ProgressBar />
      <NorthwellHeader formHeader={defaultHeader} />
      <ThemeProvider theme={nwTheme}>
      <AppRoutes />
      </ThemeProvider>
      <div className="footer">
        <Footer compact />
      </div>
    </div>
  );
}

const root = createRoot(document.getElementById("root"));
root.render(<App />);
