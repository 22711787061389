import React from 'react'

export default function progressBar() {
    return (
    <div className="progress-indicator-container">
        <div className="progress-indicator-right"></div>
        <div className="progress-indicator-left"></div>
    </div>  
    );
  }
  