import React, { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Grid, Box } from '@mui/material';
import { Typography } from '@northwell-health/nw-component-lib';

const pageTitle = 'Patient health questionnaire form';
import LowScore from './lowScore';
import HighScore from './highScore';

const ResultsPage = (props) => {
  // Breakpoints
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const progressLeft = document.getElementsByClassName(
    'progress-indicator-left',
  );
  const progressRight = document.getElementsByClassName(
    'progress-indicator-right',
  );
  const { score } = props;
  const [resultHighScore, showHigh] = useState(false);
  const [resultLowScore, showLow] = useState(false);

  useEffect(() => {
    document.querySelector('title').textContent = pageTitle;
    document.getElementById('pageTextHeader').innerHTML = pageTitle;
    progressLeft[0].style.width = '0%';
    progressRight[0].style.width = '100%';

    // Show appropriate screen based on score.
    score >= 3 ? showHigh(true) : showLow(true);
  }, []);

  return (
    <Box className="pageContainer">
      <Grid
        container
        direction="column"
        justifyContent="space-between"
        alignItems="left"
      >
        <Grid item xs={12}>
          <Typography
            type="h1"
            style={{
              marginTop: `${isMobile ? '40px' : '80px'}`,
              marginBottom: 24,
            }}
          >
            Thank you for completing your depression screening.
          </Typography>
        </Grid>
        {resultHighScore ? <HighScore /> : null}
        {resultLowScore ? <LowScore /> : null}
      </Grid>
    </Box>
  );
};
export default ResultsPage;
